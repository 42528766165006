import React from "react";
import Footer from "../components/Footer";
import NavigationBar from "../components/NavigationBar";
import { Outlet } from "react-router-dom";
import FloatNav from "../components/FloatNav";
import BackToTop from "../components/BackToTop";

const Layout = () => {
  return (
    <div className="bg-wave">
      <NavigationBar />
      <Outlet />
      <Footer />
      <FloatNav />
      <BackToTop />
    </div>
  );
};

export default Layout;
